export const chemicalAboutDocsList = [
  {
    id: 1,
    name: "MSZ EN ISO/IEC 17025:2018 (NAH-1-1666/2024) akkreditálási okirat",
    linkSrc:
      "/assets/chemical/about/documents/akkreditalasi_okirat-ervenyes_20291107.pdf",
    typeOfDoc: "Akkreditáció",
  },
  {
    id: 2,
    name: "MSZ EN ISO/IEC 17025:2018 (NAH-1-1666/2024) akkreditálási okirat - határozat",
    linkSrc:
      "/assets/chemical/about/documents/akkreditalasi_okirat_hatarozat-ervenyes_20291107.pdf",
    typeOfDoc: "Akkreditáció",
  },
  /*   {
    id: 2,
    name: "Hatályos részletező okirat - Nem rugalmas terület",
    linkSrc: "/assets/chemical/about/documents/reszletezo-okirat_nem-rugalmas-terulet-ervenyes-20231130-compressed.pdf",
    typeOfDoc: "Akkreditáció",
  }, */
  {
    id: 3,
    name: "MSZ EN ISO 14001",
    linkSrc:
      "/assets/chemical/about/documents/Tanusitvany_NQA_ISO 14001_2015_Magyar-compressed.pdf",
    typeOfDoc: "Tanúsítvány",
  },
  {
    id: 4,
    name: "MSZ EN ISO 14001 (english)",
    linkSrc:
      "/assets/chemical/about/documents/0136-03-02-Tanus_NQA_ISO 14001_2015_Angol-compressed.pdf",
    typeOfDoc: "Tanúsítvány",
  },
  {
    id: 5,
    name: "MSZ EN ISO 9001",
    linkSrc:
      "/assets/chemical/about/documents/Tanusitvany_NQA_ISO 9001_2015_Magyar-compressed.pdf",
    typeOfDoc: "Tanúsítvány",
  },
  {
    id: 6,
    name: "MSZ EN ISO 9001 (english)",
    linkSrc:
      "/assets/chemical/about/documents/Balint Analitika_NQA_ISO 9001_2015_Angol-compressed.pdf",
    typeOfDoc: "Tanúsítvány",
  },
  {
    id: 7,
    name: "MSZ ISO 45001",
    linkSrc:
      "/assets/chemical/about/documents/MEBIR_tanusitvany_bmr021_magyar_alairt-compressed.pdf",
    typeOfDoc: "Tanúsítvány",
  },
  /*   {
    id: 8,
    name: "Hatályos részletező okirat - Rugalmas terület",
    linkSrc:
      "/assets/chemical/about/documents/reszletezo-okirat_rugalmas-terulet-ervenyes-20230927-compressed.pdf",
    typeOfDoc: "Akkreditáció",
  }, */
  {
    id: 8,
    name: "Hatályos részletező okirat",
    linkSrc:
      "/assets/chemical/about/documents/reszletezo_okirat_ervenyes_20291107-compressed.pdf",
    typeOfDoc: "Akkreditáció",
  },
];
