import React, { useState } from "react";
import "./chemicalAboutProjects.css";
import nkfiLogo from "../../../../assets/chemical/about/projects/NKFIA_infoblokk_program_allo_2019_HU.png";
import danubeSedimentLogo from "../../../../assets/chemical/about/projects/DanubeSediment_Q2_rgb_mini.png";

const ChemicalAboutProjects = () => {
  const [openProjects, setOpenProjects] = useState({});

  const showProjectHandler = (id) => {
    setOpenProjects((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      <div className="container chemical-about-projects-container navbar-margin">
        <h1 className="heading-primary chemical-about-projects-header">
          Projektek
        </h1>
        <div className="prices-container">
          <div className="prices-wrapper">
            <p className="prices-file-text">2020-1.1.2-PIACI-KFI-2021-00234</p>
            <button
              onClick={() => showProjectHandler("project1")}
              className="btn btn-lg show-project-button"
            >
              {openProjects["project1"] ? "Bezárás" : "Megnézem"}
            </button>
          </div>
          {/* -------- project 1 -------- */}
          {openProjects["project1"] && (
            <div className="chemical-about-projects-item-container">
              <div className="chemical-about-projects-item-wrapper">
                <div className="chemical-about-projects-item-header">
                  <div className="chemical-about-projects-item-header-grid">
                    <div className="chemical-about-projects-item-header-img-container">
                      <img
                        src={nkfiLogo}
                        alt="nkfi-logo"
                        className="nkfi-logo"
                      />
                    </div>
                    <div className="chemical-about-projects-item-header-text-container">
                      <p className="chemical-about-projects-item-header-text">
                        Nemzeti Kutatási, Fejlesztési és Innovációs
                        Hivatal(NKFIH)
                      </p>
                      <a
                        href="/assets/chemical/about/projects/BA_HG_DE_BME_projektindito_sajtokozlemeny.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="chemical-about-projects-item-header-link"
                      >
                        Letölhető sajtóközlemény
                      </a>
                    </div>
                  </div>
                  <div className="chemical-about-projects-item-header-title-container">
                    <p className="chemical-about-projects-item-header-title-text">
                      Új növénykondicionáló szer kutatása toxinok termelődésének
                      visszaszorítására gabonákban, egyéb növényekben és
                      élelmiszer alapanyagok, takarmányok toxinmentesítésére
                      ipari méretű ózont felhasználó technológia fejlesztése
                    </p>
                  </div>
                </div>
                <div className="chemical-about-projects-item-body">
                  <table className="chemical-about-projects-item-body-table table">
                    <tbody>
                      <tr>
                        <th>Pályázati azonosító:</th>
                        <td>2020-1.1.2-PIACI-KFI-2021-00234</td>
                      </tr>
                      <tr>
                        <th>Támogató:</th>
                        <td>
                          Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal
                          (NKFIH)
                        </td>
                      </tr>
                      <tr>
                        <th>Futamidő</th>
                        <td>2021. szeptember 1. - 2024. augusztus 31.</td>
                      </tr>
                      <tr>
                        <th>Konzorciumi partnerek:</th>
                        <td>
                          <ul>
                            <li>Bálint Analitika Kft. (konzorciumvezető)</li>
                            <li> Debreceni Egyetem</li>
                            <li>
                              Budapesti Műszaki és Gazdaságtudományi Egyetem
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th>Szerződött támogatás összege:</th>
                        <td>799.550.000 Ft</td>
                      </tr>
                      <tr>
                        <th>A támogatás mértéke (%-ban)</th>
                        <td>68,99%</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="chemical-about-projects-item-body-text-container">
                    <p className="chemical-about-projects-item-body-title">
                      Pályázat összefoglalója:
                    </p>
                    <p className="chemical-about-projects-item-body-text">
                      A teljes nemzetgazdaságot súlyosan érinti a kenyérgabonák
                      időjárás okozta toxin szennyeződése, amely a gabonák
                      megsemmisítéséhez vezet. Nemzetgazdasági érdek tehát az
                      egészséges és gazdaságilag veszteségmentes növények
                      termesztése, előállítása, illetve belföldi és külföldi
                      értékesítése egyaránt. A projektben egy technológia és
                      ehhez kapcsolódó komplex szolgáltatás fejlesztése valósul
                      meg fertőzött gabonák toxin és kártevő mentesítésére,
                      illetőleg a fertőzés megelőzésére.
                    </p>
                    <p className="chemical-about-projects-item-body-text">
                      {" "}
                      A jelenleg forgalomban lévő kapszaicin vegyület beszerzése
                      több milliós tétel. A célnak megfelelő szintézis
                      kidolgozása során minimalizálva lesz a drága segédanyagok
                      használata, kerülve a felesleges származékképzést, továbbá
                      nagy hangsúlyt fektetve az atomhatékony reakciók
                      kiválasztására, az energiafelhasználás csökkentésére,
                      valamint a fenntarthatóság biztosítására.{" "}
                    </p>
                    <p className="chemical-about-projects-item-body-text">
                      A konvencionális termesztés technológiákkal szemben az
                      allelokemikáliák alkalmazásán alapuló integrált
                      növényvédelmi technológia alkalmas lehet már emelt szintű,
                      magasabb követelményrendszert megfogalmazó integrált
                      növényvédelmi technológiai rendszerek meghonosítására,
                      amely során lehetőség lesz a hasznos élő szervezetek
                      kímélésére.
                    </p>
                    <p className="chemical-about-projects-item-body-text">
                      {" "}
                      A piacon jelen lévő szereplőkkel szemben a szolgáltatás
                      proaktív és preventív választ kíván adni a problémára. A
                      kifejlesztett technológiákra (kapszaicin költséghatékony
                      szintetikus előállítása és ózonos gabonafertőtlenítés)
                      mutatkozó piaci igény biztosítja a tervezett
                      szolgáltatásaink életképességét. A pályázatban felvetett
                      problémakör nem csak Magyarország élelmiszeriparát érinti,
                      hanem a világon köztudott nehézség, ezért a kutatás
                      nemzetközi jelentőséggel bír.
                    </p>
                    <p className="chemical-about-projects-item-body-text">
                      {" "}
                      A konzorciumvezető BÁLINT ANALITIKA Kft. Magyarország
                      egyik legnagyobb szakmai tapasztalattal rendelkező és
                      egyik legismertebb kémiai és orvosdiagnosztikai laborja,
                      amely számos eredményes kutatási projektben vett már
                      részt.{" "}
                    </p>
                    <p className="chemical-about-projects-item-body-text">
                      Pályázat eredményei:
                    </p>
                    <ul className="chemical-about-projects-text-ul">
                      <li>
                        1. munkaszakasz (2021. szeptember 1. - 2022. augusztus
                        31.)
                      </li>
                      <li>
                        2. munkaszakasz (2022. szeptember 1. - 2023. augusztus
                        31.)
                      </li>
                      <li>
                        3. munkaszakasz (2023. szeptember 1. - 2024. augusztus
                        31.)
                      </li>
                    </ul>
                    <p className="chemical-about-projects-item-body-text">
                      A 2020-1.1.2-PIACI-KFI-2021-00234 számú projekt az
                      Innovációs és Technológiai Minisztérium Nemzeti Kutatási
                      Fejlesztési és Innovációs Alapból nyújtott támogatásával,
                      a 2020-1.1.2-PIACI KFI pályázati program finanszírozásában
                      valósult meg.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="prices-wrapper">
            <p className="prices-file-text">KFI_16-1-2017-0240</p>
            <button
              onClick={() => showProjectHandler("project2")}
              className="btn btn-lg show-project-button"
            >
              {openProjects["project2"] ? "Bezárás" : "Megnézem"}
            </button>
          </div>
        </div>
        {/* project 2 */}{" "}
        {openProjects["project2"] && (
          <div className="chemical-about-projects-item-container">
            <div className="chemical-about-projects-item-wrapper">
              <div className="chemical-about-projects-item-header">
                <div className="chemical-about-projects-item-header-grid">
                  <div className="chemical-about-projects-item-header-img-container">
                    <img src={nkfiLogo} alt="nkfi-logo" className="nkfi-logo" />
                  </div>
                  <div className="chemical-about-projects-item-header-text-container">
                    <p className="chemical-about-projects-item-header-text">
                      Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal(NKFIH)
                    </p>
                  </div>
                </div>
                <div className="chemical-about-projects-item-header-title-container">
                  <p className="chemical-about-projects-item-header-title-text">
                    Talajok és különböző növényfajok/fajták izotóparányának
                    vizsgálatán alapuló származási helyet meghatározó
                    eredetvizsgálati módszer fejlesztése
                  </p>
                </div>
              </div>
              <div className="chemical-about-projects-item-body">
                <table className="chemical-about-projects-item-body-table table">
                  <tbody>
                    <tr>
                      <th>Pályázati azonosító:</th>
                      <td>KFI_16-1-2017-0240</td>
                    </tr>
                    <tr>
                      <th>Támogató:</th>
                      <td>
                        Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal
                        (NKFIH)
                      </td>
                    </tr>
                    <tr>
                      <th>Kedvezményezett neve:</th>
                      <td>
                        BÁLINT ANALITIKA Mérnöki Kutató és Szolgáltató Kft.
                      </td>
                    </tr>
                    <tr>
                      <th>Tényleges befejezés dátuma:</th>
                      <td>2019. június 30.</td>
                    </tr>
                    <tr>
                      <th>Szerződött támogatás összege:</th>
                      <td>499.635.822 Ft</td>
                    </tr>
                    <tr>
                      <th>A támogatás mértéke (%-ban)</th>
                      <td>55,71 %</td>
                    </tr>
                  </tbody>
                </table>
                <div className="chemical-about-projects-item-body-text-container">
                  <p className="chemical-about-projects-item-body-title">
                    Pályázat összefoglalója:
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                    A mezőgazdasági termékek eredetének azonosítása jelenleg a
                    termék csomagolásán feltüntetett származási hely
                    megjelöléssel történik, pl. a Kínában termesztett fokhagyma
                    Magyarország területén történő átcsomagolásakor származási
                    helyként Magyarországot tüntetik fel. Ez a gyakorlat
                    Európában általános.{" "}
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                    {" "}
                    A mezőgazdasági termékek nyomon követéséhez nem a származási
                    helyet, hanem a termő helyet kellene megjelölni. A projekt
                    célja a hazai termőhelyek talajában található fémek,
                    félfémek és ritkaföldfémek mennyiségére és az egyes fémes és
                    nem fémes elemek izotóparányaira jellemző sokszínűség
                    kutatása volt. Továbbá egy olyan módszer kidolgozása, amely
                    alkalmas a növényi eredetű termékek termőhelyének objektív
                    azonosítására. A kutatás során az ország számos pontján
                    talaj és növény mintákat gyűjtöttek, melyeket nagy
                    felbontású ICP-MS (induktív csatolású plazma
                    tömegspektrométer) segítségével vizsgáltak. A mérési
                    eredmények alapján egy, a Magyarország termőterületeinek
                    izotóparányait tartalmazó adatbázis jött létre.{" "}
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                    A biológia tudományából ismert statisztikai módszerek
                    segítségével keresték a termény és termőhely közötti
                    kapcsolatot. A létrejött adatbázis szoftver
                    referenciapontokra jellemző elem- és izotóp összetétel
                    mellett tartalmazza az azonosításhoz szükséges statisztikai
                    függvényeket, ezen felül a frissen beérkező adatok alapján
                    állandóan frissíti magát. A grafikus kezelőfelület pedig
                    lehetővé teszi a termés-termőhely azonosságok, illetve
                    eltérések könnyen értelmezhető megjelenítését, 3d diagramok
                    és egyéb adattömeg ábrázolási technikák segítségével.{" "}
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                    A kutatási és fejlesztési projekt eredményeként egy olyan
                    szolgáltatás jött létre, amely segítséget nyújt a hamis
                    eredetű termékek kiszűrésében és objektív alapokra
                    helyezheti az Oltalom Alatt Álló Eredetmegjelölés (OEM),
                    valamint az Oltalom Alatt Álló Földrajzi Jelzés (OFJ)
                    rendszereket.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="prices-wrapper">
          <p className="prices-file-text">DANUBESEDIMENT_Q2</p>
          <button
            onClick={() => showProjectHandler("project3")}
            className="btn btn-lg show-project-button"
          >
            {openProjects["project3"] ? "Bezárás" : "Megnézem"}
          </button>
        </div>
        {/* project 3 */}
        {openProjects["project3"] && (
          <div className="chemical-about-projects-item-container">
            <div className="chemical-about-projects-item-wrapper">
              <div className="chemical-about-projects-item-header">
                <div className="chemical-about-projects-item-header-grid">
                  <div className="chemical-about-projects-item-header-img-container">
                    <img
                      src={danubeSedimentLogo}
                      alt="danubeSediment-logo"
                      className="nkfi-logo ds-logo"
                    />
                  </div>
                  <div className="chemical-about-projects-item-header-text-container">
                    <p className="chemical-about-projects-item-header-text">
                      DanubeSediment_Q2
                    </p>
                  </div>
                </div>
                {/* <div className="chemical-about-projects-item-header-title-container">
                    <p className="chemical-about-projects-item-header-title-text">
                      Talajok és különböző növényfajok/fajták izotóparányának
                      vizsgálatán alapuló származási helyet meghatározó
                      eredetvizsgálati módszer fejlesztése
                    </p>
                  </div> */}
              </div>
              <div className="chemical-about-projects-item-body">
                <table className="chemical-about-projects-item-body-table table">
                  <tbody>
                    <tr>
                      <th>Projekt azonosító:</th>
                      <td>DanubeSediment_Q2 DRP0200029</td>
                    </tr>
                    <tr>
                      <th>Projekt neve:</th>
                      <td>
                        Danube Sediment Balance-Sustainable, Integrated
                        Transnational Sediment Quantity and Quality Management
                        in the Danube River Basin
                      </td>
                    </tr>
                    {/* <tr>
                        <th>Kedvezményezett neve:</th>
                        <td>
                          BÁLINT ANALITIKA Mérnöki Kutató és Szolgáltató Kft.
                        </td>
                      </tr>
                      <tr>
                        <th>Tényleges befejezés dátuma:</th>
                        <td>2019. június 30.</td>
                      </tr>
                      <tr>
                        <th>Szerződött támogatás összege:</th>
                        <td>499.635.822 Ft</td>
                      </tr>
                      <tr>
                        <th>A támogatás mértéke (%-ban)</th>
                        <td>55,71 %</td>
                      </tr> */}
                  </tbody>
                </table>
                <div className="chemical-about-projects-item-body-text-container">
                  <p className="chemical-about-projects-item-body-title">
                    Projekt összefoglalója:
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                    A DunaHordalék_M2 a Duna Régió Program (EU
                    társfinanszírozott) támogatásával készül, és célja a
                    hordalék mennyiségének és minőségének javítása a Duna
                    vízgyűjtőjén a környezetvédelmi célok elérése érdekében. A
                    fő eredmény a Duna vízgyűjtőjének első integrált
                    hordalékkezelési terve (ISMP). Konkrét megoldásokat és
                    felskálázási lehetőségeket fogunk javasolni, amelyeket a
                    Nemzetközi Duna Védelmi Bizottság (ICPDR) fog figyelembe
                    venni a következő Duna vízgyűjtő-gazdálkodási tervben,
                    valamint az árvízi kockázat-kezelési tervben. Ennek első
                    alkalommal történő megvalósításához elengedhetetlen lesz egy
                    továbbfejlesztett és kibővített, kombinált
                    hordalékmennyiség- és -minőség-monitoring hálózat, valamint
                    az eredmények (i) új hidromorfológiai értékelési módszer és
                    (ii) hordalékkockázat-értékelési módszer. Központi
                    jelentőségűek az innovatív közös tervezésben kidolgozott
                    hordalékkezelési intézkedések. Emiatt az érdekelt feleket a
                    kezdetektől fogva határozottan bevonjuk a vitába. Igényeik
                    közvetlenül a projektbe áramlanak. Az új intézkedések
                    kidolgozásához monitoringot és értékelést végeznek, valamint
                    esettanulmányokon alapuló számítógépes és/vagy fizikai
                    modelleket fejlesztenek. Továbbá új módszereket fejlesztenek
                    ki a hidromorfológia és a hordalékegyensúly megzavarása
                    miatti hatások kockázatának felmérésére.
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                   <strong>A projekt speciális céljai:{" "}</strong> 
                  </p>
                  <ul className="chemical-about-projects-text-ul chemical-projects-danube-list-ul">
                    <li className="chemical-about-projects-item-body-text chemical-projects-danube-list-item">
                      Fejlettebb hordalékmonitoring, adatkezelés és értékelési
                      módszerek a Duna vízgyűjtőjén
                    </li>
                    <li className="chemical-about-projects-item-body-text chemical-projects-danube-list-item">
                      A hordalékváltozás kezelésére szolgáló az érdekelt
                      felekkel közösen kialakított gyakorlati megoldások
                      megvalósíthatósága
                    </li>
                    <li className="chemical-about-projects-item-body-text chemical-projects-danube-list-item">
                      Határokon átnyúló integrált hordalékkezelési terv (ISMP)
                      kidolgozása a Duna vízgyűjtőjére. 
                    </li>
                  </ul>
                  <p className="chemical-about-projects-item-body-text">
                    További információ{" "}
                    <a href="https://interreg-danube.eu/projects/danubesediment-q2">
                      itt
                    </a>{" "}található angolul.
                  </p>

                  {/* <p className="chemical-about-projects-item-body-text">
                  Ez a projekt a <a href="">DanubeSediment </a> és  a <a href="">SIMONA</a> projektek folytatása.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="prices-wrapper">
          <p className="prices-file-text">DANUBESEDIMENT_Q2 EN</p>
          <button
            onClick={() => showProjectHandler("project4")}
            className="btn btn-lg show-project-button"
          >
            {openProjects["project4"] ? "Bezárás" : "Megnézem"}
          </button>
        </div>
        {/* project 3 */}
        {openProjects["project4"] && (
          <div className="chemical-about-projects-item-container">
            <div className="chemical-about-projects-item-wrapper">
              <div className="chemical-about-projects-item-header">
                <div className="chemical-about-projects-item-header-grid">
                  <div className="chemical-about-projects-item-header-img-container">
                    <img
                      src={danubeSedimentLogo}
                      alt="danubeSediment-logo"
                      className="nkfi-logo ds-logo"
                    />
                  </div>
                  <div className="chemical-about-projects-item-header-text-container">
                    <p className="chemical-about-projects-item-header-text">
                      DanubeSediment_Q2
                    </p>
                  </div>
                </div>
                {/* <div className="chemical-about-projects-item-header-title-container">
                    <p className="chemical-about-projects-item-header-title-text">
                      Talajok és különböző növényfajok/fajták izotóparányának
                      vizsgálatán alapuló származási helyet meghatározó
                      eredetvizsgálati módszer fejlesztése
                    </p>
                  </div> */}
              </div>
              <div className="chemical-about-projects-item-body">
                <table className="chemical-about-projects-item-body-table table">
                  <tbody>
                    <tr>
                      <th>Project ID:</th>
                      <td>DanubeSediment_Q2 DRP0200029</td>
                    </tr>
                    <tr>
                      <th>Project name:</th>
                      <td>
                        Danube Sediment Balance-Sustainable, Integrated
                        Transnational Sediment Quantity and Quality Management
                        in the Danube River Basin
                      </td>
                    </tr>
                    {/* <tr>
                        <th>Kedvezményezett neve:</th>
                        <td>
                          BÁLINT ANALITIKA Mérnöki Kutató és Szolgáltató Kft.
                        </td>
                      </tr>
                      <tr>
                        <th>Tényleges befejezés dátuma:</th>
                        <td>2019. június 30.</td>
                      </tr>
                      <tr>
                        <th>Szerződött támogatás összege:</th>
                        <td>499.635.822 Ft</td>
                      </tr>
                      <tr>
                        <th>A támogatás mértéke (%-ban)</th>
                        <td>55,71 %</td>
                      </tr> */}
                  </tbody>
                </table>
                <div className="chemical-about-projects-item-body-text-container">
                  <p className="chemical-about-projects-item-body-title">
                    Project summary:
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                  DanubeSediment_Q2 is funded by the Danube Region Programme (co-funded by the EU) and aims to improve the management of sediment quantity and quality in the Danube River Basin to achieve environmental objectives. The main output is the first Integrated Sediment Management Plan (ISMP) for the Danube River Basin. We will recommend concrete solutions and upscaling options to be taken up by International Commission for the Protection of the Danube River (ICPDR) in the next Danube River Basin Management Plan as well as Flood Risk Management Plan. To achieve this for the first time an improved and extended, combined sediment quantity and quality monitoring network and the outputs (i) new hydromorphological assessment method and (ii) sediment risk assessment method will be essential. Of central importance are the sediment management measures developed in an innovative co-design. For this reason, stakeholders are heavily involved in the discussion from the start. Their needs flow directly into the project. To develop new measures, monitoring and assessment is conducted and computer models are developed based on case studies as well as on physical model tests. Furthermore, new methods for assessing hydromorphology and the risk of the effects of a disturbed sediment balance are being developed.
                  </p>
                  <p className="chemical-about-projects-item-body-text">
                   <strong>The special objectives of the project are:{" "}</strong> 
                  </p>
                  <ul className="chemical-about-projects-text-ul chemical-projects-danube-list-ul">
                    <li className="chemical-about-projects-item-body-text chemical-projects-danube-list-item">
                    Improved sediment monitoring, data management and evaluation methods in the Danube River Basin
                    </li>
                    <li className="chemical-about-projects-item-body-text chemical-projects-danube-list-item">
                    Feasibility of practical solutions to address sediment alteration being co-created with stakeholders
                    </li>
                    <li className="chemical-about-projects-item-body-text chemical-projects-danube-list-item">
                    Developed transboundary Integrated Sediment Management Plan ISMP for the Danube River Basin
                    </li>
                  </ul>
                  <p className="chemical-about-projects-item-body-text">
                  Further Information can be found {" "}
                    <a href="https://interreg-danube.eu/projects/danubesediment-q2">
                      here
                    </a>.
                  </p>

                  {/* <p className="chemical-about-projects-item-body-text">
                  Ez a projekt a <a href="">DanubeSediment </a> és  a <a href="">SIMONA</a> projektek folytatása.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChemicalAboutProjects;
