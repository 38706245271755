import "./chemicalHome.css";
import ChemicalServices from "./ChemicalServices";
import ChemicalHomeHero from "./ChemicalHomeHero";
import ChemicalNews from "./ChemicalNews";

const ChemicalHome = () => {
  return (
    <>
      <ChemicalHomeHero />
      <ChemicalNews />
      <ChemicalServices />
    </>
  );
};

export default ChemicalHome;
